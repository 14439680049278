<template>
  <div>
    <b-progress v-show="isLoading" :value="100" :max="100" animated></b-progress>
    <b-card v-show="!isLoading">
      <template #header>
        <div class="d-flex justify-content-between px-3">
          <!-- <PartyDetail :party="selected.party" v-if="mode === 'input'" /> -->
          <div>
            <router-link
            :to="'/input-suara/calon/' + $route.params.level"
            class="h3"
          >
            <font-awesome-icon icon="arrow-left" />
          </router-link>

          <h4 class="d-inline ml-3" v-if="mode === 'confirm'">Input Suara {{ levelName }}</h4>
          </div>
        </div>
      </template>

      <b-card-text
        :style="mode === 'input' ? 'max-height: 230px; overflow-y: auto' : ''"
        class="px-5 my-3"
      >
        <!-- <b-row class="mb-2 align-items-center"  v-if="mode === 'input'">
          <b-col cols="6">Suara Partai</b-col>
          <b-col cols="6">
            <b-input
              style="width: 50%;"
              type="number"
              v-model="selected.party_vote_count"
              @click="removeZero"
              placeholder="Input suara"
              label-placement="stacked"
              min="0"
            ></b-input>
          </b-col>
        </b-row> -->
        <CalegList :caleg="list.caleg" v-if="mode === 'input'" />

        <div v-if="mode === 'confirm'">
          <b-row class="my-3">
            <b-col cols="4">
              <label>Jumlah suara sah</label>
            </b-col>
            <b-col cols="8">
              <b-input
                readonly
                type="number"
                placeholder="Jumlah suara sah"
                :value="list.caleg.length > 0 ? totalSuara : 0"
              />
            </b-col>
          </b-row>

          <b-row class="my-3">
            <b-col cols="4">
              <label>Total surat suara</label>
            </b-col>
            <b-col cols="8">
              <b-input
                autofocus
                type="number"
                v-model="form.summary.total_surat_suara"
                placeholder="Input total surat suara"
              />
            </b-col>
          </b-row>

          <b-row class="my-3">
            <b-col cols="4">
              <label>Total surat suara tidak sah</label>
            </b-col>
            <b-col cols="8">
              <b-input
                type="number"
                v-model="form.summary.total_suara_tidak_sah"
                placeholder="Input total surat suara tidak sah"
              />
            </b-col>
          </b-row>

          <b-row class="my-3">
            <b-col cols="4">
              <label>Jumlah suara tidak terpakai(Terisi Automatis)</label>
            </b-col>
            <b-col cols="8">
              <b-input
                readonly
                type="number"
                placeholder="Jumlah suara tidak terpakai"
                :value="
                  parseInt(form.summary.total_surat_suara) -
                  totalSuara -
                  parseInt(form.summary.total_suara_tidak_sah)
                "
              />
            </b-col>
          </b-row>
        </div>

        <b-progress v-show="isProgressing" :value="100" :max="100" animated></b-progress>
      </b-card-text>

      <template #footer v-if="mode === 'input'">
        <b-row class="px-5">
          <b-col cols="6">
            <h5>Total Suara</h5>
          </b-col>
          <b-col cols="6">
            <b-input
              v-model="totalSuaraPartai"
              placeholder="0"
              type="number"
              style="width: 50%"
              disabled
            ></b-input>
          </b-col>
        </b-row>
      </template>
    </b-card>

    <div v-if="!isLoading"
      :class="
        `d-flex align-items-center my-3` +
        (lastPartyNumber > 1
          ? ' justify-content-between'
          : ' justify-content-end')
      "
    >
      <b-button
        v-if="lastPartyNumber > 1"
        @click.prevent="doPrev"
        variant="warning"
        class="text-white mr-3"
        >Kembali</b-button
      >
      <b-button
        @click.prevent="doNext"
        v-if="lastPartyNumber <= list.party.length"
        variant="primary"
        >Selanjutnya</b-button
      >
      <b-button
        @click.prevent="presentAlertUpload"
        v-else
        :disabled="!list.party.length"
        variant="primary"
        >Simpan</b-button
      >
    </div>
  </div>
</template>

<script>
import iziToast from "izitoast";
import { serializeErrorMessage } from "@/_helpers";
// import PartyDetail from "./Forms/PartyDetail.vue";
import CalegList from "./Forms/CalegList.vue";

export default {
  components: {
    // PartyDetail,
    CalegList,
  },
  data() {
    return {
      mounted: false,
      unmounted: false,
      isLoading: true,
      isProgressing: false,
      mode: "input",
      level: parseInt(this.$route.params.level),
      lastPartyNumber: 1,
      regionId: "",
      levelName: "",
      localDataSuara: null,
      form: {
        summary: {
          total_surat_suara: null,
          total_suara_tidak_sah: null,
        },
      },
      selected: {
        party_vote_count: 0,
        party: null,
      },
      list: {
        party: [],
        caleg: [],
      },
    };
  },
  async unmounted() {
    this.unmounted = true;
    this.whenUnmounted();
  },
  async mounted() {
    this.mounted = true;
    this.whenMounted();

    setTimeout(() => {
      if (!this.mounted) {
        this.whenMounted();
      }
      this.mounted = false;
    }, 500);
  },
  methods: {
    removeZero (evt) {
      if (!evt.target.value || parseInt(evt.target.value) < 1) {
        evt.target.value = null
      }
    },
    async whenMounted() {
      this.checkLevel();
      await this.showLoading("Mengecek data tersimpan");
      setTimeout(async () => {
        if (this.$store.getters["account/accountData"].role === "verifikator") {
          if (!(await this.$localDb.dataSuara().get())) {
            const resp = await this.$http.get(
              process.env.VUE_APP_API_URL +
                "input-suara/verifikator/worksheet/" +
                this.regionId
            );

            let responseJson = await fetch(
              resp.data.data.petugas_json_file_path
            );
            responseJson = await responseJson.json();
            this.localDataSuara = await this.$localDb
              .dataSuara()
              .saveAll(responseJson.data_suara);

            const summary = await this.$localDb
              .dataSummarySuara()
              .save(responseJson.summary);
            if (summary) {
              this.form.summary.total_surat_suara = summary.total_surat_suara;
              this.form.summary.total_suara_tidak_sah =
                summary.total_suara_tidak_sah;
            }
          } else {
            this.localDataSuara = await this.$localDb.dataSuara().get();
            const summary = await this.$localDb.dataSummarySuara().get();
            if (summary) {
              this.form.summary.total_surat_suara = summary.total_surat_suara;
              this.form.summary.total_suara_tidak_sah =
                summary.total_suara_tidak_sah;
            }
          }
        } else {
          this.localDataSuara = await this.$localDb
            .dataSuara()
            .init(this.regionId, this.level);
        }

        // await this.hideLoading();
        if (this.localDataSuara.level != this.level) {
          let levelName = "";
          if (this.localDataSuara.level === 1) {
            levelName = "DPR";
          } else if (this.localDataSuara.level === 2) {
            levelName = "DPRD TK I";
          } else {
            levelName = "DPRD TK II";
          }

          await this.$swal
            .fire({
              title: "Sepertinya Anda Telah Mengisi Data Sebelumnya",
              text:
                "Sistem menemukan anda belum menyelesaikan input suara " +
                levelName +
                ", apakah anda ingin menghapus data sebelumnya?",
              icon: "warning",
              showCancelButton: true,
              confirmButtonText: "Yakin",
              cancelButtonText: "Tidak",
            })
            .then(async (result) => {
              if (result.isConfirmed) {
                await this.$localDb.dataSuara().reset();
                this.localDataSuara = await this.$localDb
                  .dataSuara()
                  .init(this.regionId, this.level);
                this.initData();
              } else {
                setTimeout(async () => {
                  this.$router.push("/input-suara/calon/2");
                }, 500);
              }
            });
        } else {
          this.initData();
        }
      }, 800);
    },
    whenUnmounted() {
      this.lastPartyNumber = 1;
      this.mode = "input";
      if (this.selected.party && this.list.caleg.length > 0) {
        this.$localDb
          .dataSuara()
          .saveByParty(this.selected.party.id, this.list.caleg, this.selected.party_vote_count);
      }
    },
    checkLevel() {
      if (this.level === 1) {
        this.levelName = "DPR";
        this.regionId = process.env.VUE_APP_DPR_ID;
      } else if (this.level === 2) {
        this.levelName = "DPRD TK I";
        this.regionId = process.env.VUE_APP_DPRD_ID;
      } else {
        this.levelName = "DPRD TK II";
        this.regionId = process.env.VUE_APP_DPRD_II_ID;
      }
    },
    async initData() {
      await this.showLoading();
      await this.getParty();
      await this.getCaleg();
      this.isLoading = false;
      // await this.hideLoading();
    },
    async showLoading(text = "Memuat Data...") {
      await this.$swal.fire({
        title: "Mohon Tunggu",
        text: text,
        timer: 500,
        timerProgressBar: true,
        showConfirmButton: false,
      });
      return Promise.resolve("success");
    },
    // async hideLoading() {
    //   if (this.loadingController) {
    //     await this.loadingController.dismiss();
    //   }
    //   return Promise.resolve("success");
    // },
    selectParty() {
      const selectedParty = this.list.party.filter((party) => {
        return party.sort === this.lastPartyNumber;
      });
      this.selected.party = selectedParty[0];
    },
    async getParty() {
      try {
        // const resp = await this.$http.get(process.env.VUE_APP_API_URL + 'parties?limit=20&order=sort&ascending=1');
        // this.list.party = resp.data.data
        // this.selectParty()
        this.list.party = await this.$localDb.dataPartai().init();
        this.selectParty();
      } catch (error) {
        await iziToast.error({
          title: "Error",
          theme: "dark",
          message: serializeErrorMessage(error),
        });
      }
      Promise.resolve("success");
    },
    async getCaleg() {
      try {
        const savedData = this.localDataSuara.votes_data.find((localData) => {
          return localData.party_id === this.selected.party.id;
        });

        if (savedData) {
          this.selected.party_vote_count = savedData.party_vote_count
          this.list.caleg = savedData.votes_data;
        } else {
          let resp = await this.$http.get(
            process.env.VUE_APP_API_URL +
              "input-suara/daftar-caleg/" +
              this.regionId +
              "/" +
              this.selected.party.id
          );
          resp = resp.data.data;
          resp.sort((a, b) => {
            return a.number - b.number;
          });
          const result = resp.map((caleg) => {
            let vote_count = null;
            return {
              ...caleg,
              vote_count: vote_count,
            };
          });
          this.list.caleg = result;
          this.selected.party_vote_count = 0
        }
      } catch (error) {
        await iziToast.error({
          title: "Error",
          theme: "dark",
          message: serializeErrorMessage(error),
        });
      }
      Promise.resolve("success");
    },
    doValidation() {
      const emptyVoteCount = this.list.caleg.filter((caleg) => {
        return (
          !caleg.vote_count ||
          (caleg.vote_count && caleg.vote_count.trim() === "")
        );
      });
      return emptyVoteCount;
    },
    async doNext() {
      this.isLoading = true;
      this.list.caleg.forEach((caleg) => {
        if (caleg.vote_count === null || caleg.vote_count === "") {
          caleg.vote_count = 0;
        }
      });

      await this.showLoading("Menyimpan Suara...");
      this.localDataSuara = await this.$localDb
        .dataSuara()
        .saveByParty(this.selected.party.id, this.list.caleg, this.selected.party_vote_count);
      this.lastPartyNumber++;
      if (this.lastPartyNumber > this.list.party.length) {
        this.mode = "confirm";
      } else {
        this.selectParty();
        await this.getCaleg();
      }
      this.isLoading = false;
    },
    async doPrev() {
      await this.showLoading();
      this.mode = "input";
      this.lastPartyNumber--;
      this.selectParty();
      await this.getCaleg();
      // await this.hideLoading();
    },
    async presentAlertUpload() {
      if (
        !this.form.summary.total_surat_suara ||
        this.form.summary.total_surat_suara.trim === ""
      ) {
        await iziToast.error({
          title: "Error",
          theme: "dark",
          message: "Lengkapi total surat suara!",
        });
      } else if (
        parseInt(this.form.summary.total_surat_suara) <
        parseInt(this.totalSuara)
      ) {
        await iziToast.error({
          title: "Error",
          theme: "dark",
          message:
            "Total surat suara tidak boleh lebih kecil dari " +
            this.totalSuara +
            " !",
        });
      } else if (
        !this.form.summary.total_suara_tidak_sah ||
        this.form.summary.total_suara_tidak_sah.trim === ""
      ) {
        await iziToast.error({
          title: "Error",
          theme: "dark",
          message: "Lengkapi total surat suara tidak sah!",
        });
      } else if (
        parseInt(this.form.summary.total_suara_tidak_sah) >
        parseInt(this.form.summary.total_surat_suara) -
          parseInt(this.totalSuara)
      ) {
        await iziToast.error({
          title: "Error",
          theme: "dark",
          message:
            "Total surat suara tidak boleh lebih besar dari " +
            (parseInt(this.form.summary.total_surat_suara) -
              parseInt(this.totalSuara)) +
            " !",
        });
      } else {
        await this.$swal
          .fire({
            title: "Pastikan Data Sudah Benar!",
            text: "Apakah anda yakin akan menyimpan data suara?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Ya",
            cancelButtonText: "Tidak",
          })
          .then((result) => {
            if (result.value) {
              this.doUpload();
            }
          });
      }
    },
    async doUpload() {
      this.isProgressing = true;
      await this.showLoading("Menyimpan Data...");
      try {
        const dataSuara = await this.$localDb.dataSuara().get();
        await this.$http.post(
          process.env.VUE_APP_API_URL +
            "input-suara/" +
            (this.$store.getters["account/accountData"].role === "verifikator"
              ? "verifikator"
              : "petugas") +
            "/worksheet/" +
            this.regionId,
          {
            form: {
              summary: {
                total_suara_sah: parseInt(this.totalSuara),
                total_surat_suara: parseInt(
                  this.form.summary.total_surat_suara
                ),
                total_suara_tidak_sah: parseInt(
                  this.form.summary.total_suara_tidak_sah
                ),
                total_suara_tidak_terpakai: parseInt(
                  parseInt(this.form.summary.total_surat_suara) -
                    this.totalSuara -
                    parseInt(this.form.summary.total_suara_tidak_sah)
                ),
              },
              data_suara: dataSuara,
            },
          }
        );

        await this.$localDb.dataSuara().reset();
        this.list.caleg = [];
        this.selected.party = null;
        this.lastPartyNumber = 1;
        this.mode = "input";

        await iziToast.success({
          title: "Success",
          theme: "dark",
          message: "Data suara berhasil disimpan!",
        });
        this.$router.push("/input-suara/calon/2");
        this.isProgressing = false;
      } catch (error) {
        await iziToast.error({
          title: "Error",
          theme: "dark",
          message: serializeErrorMessage(error),
        });
        this.isProgressing = false;
      }
    },
  },
  watch: {},
  computed: {
    totalSuaraPartai() {
      let total = 0;
      this.list.caleg.forEach((caleg) => {
        total += parseInt(caleg.vote_count ? caleg.vote_count : 0);
      });
      total += parseInt(this.selected.party_vote_count ? this.selected.party_vote_count : 0)
      return total;
    },
    totalSuara() {
      let total = 0;
      if (this.localDataSuara && this.localDataSuara.votes_data.length) {
        this.localDataSuara.votes_data.forEach((localDataSuara) => {
          localDataSuara.votes_data.forEach((partyData) => {
            total += parseInt(partyData.vote_count);
          });
          total += parseInt(localDataSuara.party_vote_count ? localDataSuara.party_vote_count : 0)
        });
      }
      return total;
    },
  },
};
</script>